/** @format */

import React from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../app/components/dashboard/Dashboard";
import ListOrder2 from "../app/components/orders/list2";
import OrderDetail from "../app/components/orders/detail";
import ListTracking from "../app/components/tracking/list";
import TrackingDetail from "../app/components/tracking/detail";
import Serviceability from "../app/components/serviceability";
import ListCourier from "../app/components/courier/List";
import MerchantLocations from "../../src/app/components/merchant-onboarding/locations/pickupLocation/index";
import MerchantUsers from "../app/components/merchant-onboarding/users";
import MerchantPreview from "../app/components/merchant-onboarding/preview";
import NDRListing from "../app/components/ndr";
import NPRListing from "../app/components/npr";
import CreateOrderStep2 from "../app/components/orders/create/step2";
import PreviewOrder from "../app/components/orders/create/preview";
import CreateBulkOrder from "../app/components/orders/create/bulkOrder";
import Wallet from "../app/components/wallet";
import Setting from "../app/components/setting";
import ShipmentCalculator from "../app/components/shipment-calculator";
import ShipmentRateCalculatorPTL from "../app/components/shipment-calculator-ptl";
import ListWeightOrders from "../app/components/weight/list";
import Notification from "../app/components/notification";
import Profile from "../app/components/profile";
import Reports from "../app/components/reports";
import ControlTower from "../app/components/ct";
import BulkDownloadLabels from "../app/components/orders/download/BulkDownloadLabels";
import axios from "axios";
import store from "../app/redux/store";
import { hasPageAccess, isAdmin } from "../app/helpers/ReduxHelpers";
import StuckOrders from "../app/components/orders/list2/StuckOrders";
// import B2BOrders from "../app/components/orders/list2/B2BShipments";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../app/redux/userSlice";
import ReverseOrder from "../app/components/orders/create/ReverseOrder";
import ReverseOrders from "../app/components/orders/list2/ReverseOrders";
import ProtectedRoute from "./ProtectedRoute";
import ChangePassword from "../app/components/profile/changePassword";
import AllChannelOrders from "../app/components/orders/list2/ChannelOrders";
import RateCard from "../app/components/RateCard";
// import MonthOnMonthAnalysis from "../app/components/dashboard/MonthOnMonthAnalysis";
import Bulkordercreate from "../app/components/orders/create/bulkordercreate";
import CODRemittance from "../app/components/COD Remittance/CODRemittance";
import MerchantInvoice from "../app/components/merchant Invoice/MerchantInvoice";
import ConfiguWebhook from "../app/components/webhook/ConfiguWebhook";
import ViewWebHook from "../app/components/webhook/ViewwebHook";
import Rules from "../app/components/Merchant Rule/Rules";
import Manifest from "../app/components/orders/list2/Manifest";
import WeightDiscrepancyUpload from "../app/components/weight/list/WeightDiscrepancy";
import BulkTracking from "../app/components/orders/list2/BulkTracking";
import PtlPickupRequests from "../app/components/orders/list2/PickupRequest";
import UploadTracking from "../app/components/orders/list2/UploadTracking";
import PickupRequestDetails from "../app/components/orders/list2/PickupRequestDetails";
import MerchantDeliveryLocations from "../app/components/merchant-onboarding/locations/deliveryLocations";
import EmailConfig from "../app/components/EmaiConfigs/EmailConfig";
import BulkInvoiceDownload from "../app/components/orders/download/BulkInvoiceDownload";
import EscalationManagement from "../app/components/EscalationManagement";
import Performance from "../app/components/dashboard/performance/Performance";
import View from "../app/components/EscalationManagement/View";
import Sidebar from "../app/menu/Sidebar";
import TopBar from "../app/menu/TopBar";
import CreatePTLShipment from "../app/components/B2BOrder/CreatePTLShipment";
import CreateB2COrder from "../app/components/orders/CreateV2/CreateB2COrder";
import ProshipPilot from "../app/components/ProshipPilot";
import CreateB2COrderRVP from "../app/components/orders/CreateRVPV2/CreateB2COrder";
import AppointmentDeliveries from "../app/components/orders/list2/AppointmentDeliveries";
// import PtlPhoneConfig from "../app/components/PtlPhoneConfig";
import ZoneMapping from "../app/components/zone Mapping";
import ReturnOrders from "../app/components/orders/returns";

axios.interceptors.request.use(
  (config) => {
    const {
      user: {
        userInfo: { accessToken },
      },
    } = store.getState();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

function Layout() {
  const { pathname } = useLocation();
  const { userInfo } = useSelector((state) => state.user);

  const kycStatus = userInfo?.merchant?.status;

  if (kycStatus && kycStatus === "KYC_PENDING" && pathname !== "/profile") {
    return <Navigate to='/profile' replace />;
  }

  return (
    <div>
      <TopBar />

      <div>
        <Sidebar />
        <div style={{ flexGrow: 1, padding: "20px" }}>
          <Outlet /> {/* This renders the matched child route */}
        </div>
      </div>
    </div>
  );
}

export default function PrivateRoutes() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user)

  let intitalRoute = "";
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      // console.log("eexpired");
      if (
        err?.response?.data?.message?.toLowerCase() === "jwt token is expired"
      ) {
        dispatch(logout());
      }
      return Promise.reject(err);
    }
  );

  // if (isAdmin() || hasPageAccess("/orders")) {
  //   intitalRoute = "/orders";
  // if (isAdmin()) {
  //   intitalRoute = "/orders";
  if (isAdmin() && userInfo?.merchant?.isD2CMerchant) {
    intitalRoute = "/orders";
  } else if (isAdmin() && userInfo?.merchant?.isPTLMerchant) {
    intitalRoute = "/b2b-orders";
  } else if (hasPageAccess("/b2b-orders") && userInfo?.merchant?.isPTLMerchant) {
    intitalRoute = "/b2b-orders";
  } else if (hasPageAccess("/orders")) {
    intitalRoute = "/orders";
  } else if (hasPageAccess("/dashboard")) {
    intitalRoute = "/dashboard";
  } else if (hasPageAccess("/reports")) {
    intitalRoute = "/reports";
  } else if (hasPageAccess("/control-tower")) {
    intitalRoute = "/control-tower";
  } else {
    console.log('here')
    intitalRoute = "/change-password";
  }
  
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Redirect to dashboard */}
        <Route exact element={<Navigate to={intitalRoute} />} path='/login' />
        <Route
          exact
          element={<Navigate to={intitalRoute} />}
          path='/forgot-password'
        />
        <Route
          exact
          element={<Navigate to={intitalRoute} />}
          path='/reset-password'
        />
        <Route exact element={<Navigate to={intitalRoute} />} path='/signup' />
        <Route exact element={<Navigate to={intitalRoute} />} path='/otp' />
        <Route
          exact
          element={<Navigate to={intitalRoute} />}
          path='/create-new-password'
        />
        <Route exact element={<Navigate to={intitalRoute} />} path='/toc' />
        {/* Redirection End */}

        <Route
          path='/'
          element={
            <ProtectedRoute role='DASHBOARD'>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path='/dashboard'
          element={
            <ProtectedRoute role='DASHBOARD'>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path='/month-on-month'
          element={
            <ProtectedRoute role='DASHBOARD'>
              {/* <MonthOnMonthAnalysis /> */}
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path='/control-tower'
          element={
            <ProtectedRoute role='CT'>
              <ControlTower />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/proship-pilot'
          element={
            <ProtectedRoute role='CT'>
              <ProshipPilot />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path='/orders'
          element={
            <ProtectedRoute role='ORDERS'>
              <ListOrder2 shipmentType='B2C' />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/return-orders'
          element={
            <ProtectedRoute role='ORDERS'>
              <ReturnOrders />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/channel-orders'
          element={
            <ProtectedRoute role='ORDERS'>
              <AllChannelOrders />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/orders/create-reverse/:id'
          element={
            <ProtectedRoute role='ORDERS'>
              <ReverseOrder />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/reverse-orders'
          element={
            <ProtectedRoute role='ORDERS'>
              <ReverseOrders />
            </ProtectedRoute>
          }
        />
        <Route exact path='/stuck-orders' element={<StuckOrders />} />
        <Route
          exact
          path='/b2b-orders'
          element={<ListOrder2 shipmentType='B2B' />}
        />
        <Route
          exact
          path='/create-b2b-orders'
          element={<CreatePTLShipment />}
        />
        <Route
          exact
          path='/upload-b2b-orders'
          element={<Bulkordercreate shipmentType='B2B' />}
        />
        <Route
          exact
          path='/ptl-pickup-requests'
          element={<PtlPickupRequests />}
        />
        <Route
          exact
          path='ptl-pickup-requests/:id'
          element={<PickupRequestDetails />}
        />
        <Route exact path='/b2b_tracking_upload' element={<UploadTracking />} />
        <Route exact path='/order-detail' element={<OrderDetail />} />
        <Route exact path='/tracking-list' element={<ListTracking />} />
        <Route exact path='/tracking-detail' element={<TrackingDetail />} />
        <Route
          exact
          path='/serviceable-pincodes'
          element={<Serviceability />}
        />
        <Route exact path='/couriers' element={<ListCourier />} />
        <Route exact path='/merchant-step2' element={<MerchantLocations />} />
        <Route
          exact
          path='/delivery-location'
          element={<MerchantDeliveryLocations />}
        />
        <Route exact path='/merchant-step3' element={<MerchantUsers />} />
        <Route exact path='/merchant-preview' element={<MerchantPreview />} />
        <Route
          exact
          path='/ndr'
          element={
            <ProtectedRoute role='NDR'>
              <NDRListing />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/npr'
          element={
            <ProtectedRoute role='NDR'>
              <NPRListing />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/weight-discrepancy'
          element={
            <ProtectedRoute role='WEIGHT_DESC'>
              <ListWeightOrders />
            </ProtectedRoute>
          }
        />
        <Route exact path='/download-labels' element={<BulkDownloadLabels />} />
        <Route
          exact
          path='/download-invoice'
          element={<BulkInvoiceDownload />}
        />
        <Route
          exact
          path='/create-order'
          element={
            <ProtectedRoute role='ORDERS'>
              <CreateB2COrder />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/create-order-rvp'
          element={
            <ProtectedRoute role='ORDERS'>
              <CreateB2COrderRVP />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path='/pending-pickups'
          element={
            <ProtectedRoute role='PENDINGPICKUP'>
              <Manifest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/bulk-tracking'
          element={
            <ProtectedRoute role='ORDERS'>
              <BulkTracking />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/bulk-order-create'
          element={
            <ProtectedRoute role='ORDERS'>
              <Bulkordercreate shipmentType='B2C' />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/ptl-appointment-deliveries'
          element={
            <ProtectedRoute role='ORDERS'>
              <AppointmentDeliveries />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/create-order-step2'
          element={<CreateOrderStep2 />}
        />
        <Route exact path='/preview-order' element={<PreviewOrder />} />
        <Route exact path='/bulk-order' element={<CreateBulkOrder />} />
        <Route
          exact
          path='/wallet'
          element={
            <ProtectedRoute role='FINANCE'>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/setting'
          element={
            <ProtectedRoute role='SETTINGS'>
              <Setting />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/escalation_management'
          element={
            <ProtectedRoute role='TOOLS'>
              <EscalationManagement />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/escalation_management/view'
          element={
            <ProtectedRoute role='TOOLS'>
              <View />
            </ProtectedRoute>
          }
        />
        <Route exact path='/rate-calculator' element={<ShipmentCalculator />} />
        <Route
          exact
          path='/rate-calculator-ptl'
          element={<ShipmentRateCalculatorPTL />}
        />
        <Route exact path='/notification' element={<Notification />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/change-password' element={<ChangePassword />} />
        <Route
          exact
          path='/reports'
          element={
            <ProtectedRoute role='REPORTS'>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/cod-remittance'
          element={
            <ProtectedRoute role='FINANCE'>
              <CODRemittance />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path='/merchant-invoice'
          element={
            <ProtectedRoute role='FINANCE'>
              <MerchantInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path='weight-discrepancy-bulk-action'
          element={
            <ProtectedRoute role='WEIGHT_DESC'>
              <WeightDiscrepancyUpload />
            </ProtectedRoute>
          }
        />

        <Route exact path='/rate-card' element={<RateCard />} />
        <Route exact path='/view-webhook' element={<ViewWebHook />} />
        <Route exact path='/configue-webhook' element={<ConfiguWebhook />} />
        <Route exact path='/merchant-rules' element={<Rules />} />
        <Route exact path='/zone-mapping' element={<ZoneMapping />} />
        <Route exact path='/email-config' element={<EmailConfig />} />
        {/* <Route exact path='/ptl-phone-config' element={<PtlPhoneConfig />} /> */}
        <Route exact path='/performance' element={<Performance />} />
        {/* <Route exact path="/report-config" element={<ReportConfig />} /> */}
      </Route>
    </Routes>
  );
}
